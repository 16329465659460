import { createStore } from 'vuex'

export default createStore({
  state: {
    navTitle: 'Elektrotechnik-Rechner.at',
    navTitleRoute: 'start',
    isLoading: true
  },
  mutations: {
    updateNavTitle (state, { title, route }) {
      state.navTitle = title
      state.navTitleRoute = route
    },
    updateLoadingStatus (state, status) {
      state.isLoading = status
    }
  }
})

<template>
  <div id="app">
    <!-- Header -->
    <section class="hero is-primary">
      <!-- Navigation -->
      <div class="hero-head">
        <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <router-link :to="{name: navTitleRoute}" class="navbar-item has-text-weight-semibold has-spacing-1">
              <span class="is-size-5" v-html="navTitle"></span>
            </router-link>

            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu" @click="toggleNavbar">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div class="navbar-menu" id="navMenu">
            <div class="navbar-start"></div>
            <div class="navbar-end has-spacing-1">
              <router-link :to="{name: 'start'}" class="navbar-item">Startseite</router-link>
              <div class="navbar-item has-dropdown" v-bind:class="{ 'is-active': openToolsDropdown }">
                <a class="navbar-link" @click="toggleNavDropdown('tools')">Werkzeuge</a>
                <div class="navbar-dropdown is-right" v-bind:class="{ 'is-active': openToolsDropdown }">
                  <router-link v-on:click="toggleNavDropdown('tools')" :to="{name: 'calculators'}" class="navbar-item">Rechner</router-link>
                  <!--<a @click="redirectTo('converter')" class="navbar-item">Konverter</a>-->
                </div>
              </div>
              <div class="navbar-item has-dropdown" v-bind:class="{ 'is-active': openKnowledgeDropdown }">
                <a class="navbar-link" @click="toggleNavDropdown('knowledge')">Wissen</a>
                <div class="navbar-dropdown is-right" v-bind:class="{ 'is-active': openKnowledgeDropdown }">
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'network-engineering'}" class="navbar-item">Netzwerktechnik</router-link>
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'electrical-elements'}" class="navbar-item">Elektrische Bauelemente</router-link>
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'pins'}" class="navbar-item">Pin- und Steckerbelegungen</router-link>
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'tables'}" class="navbar-item">Tabellen</router-link>
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'formulas'}" class="navbar-item">Formelsammlung</router-link>
                  <router-link v-on:click="toggleNavDropdown('knowledge')" :to="{name: 'glossary'}" class="navbar-item">Glossar</router-link>
                </div>
              </div>
              <div class="navbar-item has-dropdown" v-bind:class="{ 'is-active': openBookmarksDropdown }">
                <a class="navbar-link" @click="toggleNavDropdown('bookmarks')">Lesezeichen</a>
                <div class="navbar-dropdown is-right" v-bind:class="{ 'is-active': openBookmarksDropdown }">
                  <router-link v-on:click="toggleNavDropdown('bookmarks')" :to="{name: 'software'}" class="navbar-item">Nützliche Programme</router-link>
                  <router-link v-on:click="toggleNavDropdown('bookmarks')" :to="{name: 'learning'}" class="navbar-item">Elektrotechnik lernen</router-link>
                  <!--<router-link v-on:click="toggleNavDropdown('bookmarks')" :to="{name: 'bookmarks'}" class="navbar-item">Sonstige</router-link>-->
                  <!--<a @click="redirectTo('converter')" class="navbar-item">Konverter</a>-->
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <!-- Stage -->
      <div class="hero-body is-hidden-mobile">
        <div class="container has-text-centered">
          <p class="title anchor-pointer has-text-weight-normal">Elektrotechnik-Rechner.at</p>
          <p class="subtitle anchor-pointer">Formeln, Rechner, Tabellen und andere hilfreiche Tools für deine Elektrotechnik-Projekte</p>
        </div>
      </div>
    </section>

    <div class="section app-body pt-5 pb-5">
      <div class="container">
        <div v-if="isLoading" class="pt-6 pb-6">
          <div class="loader"></div>
        </div>
        <!-- Content -->
        <router-view />
      </div>
    </div>

    <!-- Footer -->
    <footer class="footer has-text-light is-size-7">
      <div class="container">
        <div class="content has-text-centered-mobile">
          <p>
            Vermisst du etwas Wichtiges?
            <br />
            Vorschläge, Wünsche und Feedback sind jederzeit willkommen - einfach eine Mail an
            <a href="mailto:kontakt@elektrotechnik-rechner.at">kontakt@elektrotechnik-rechner.at</a>.
          </p>
          <p>
            Die mit Sternchen (*) gekennzeichneten Links sind sogenannte Affiliate-Links. Wenn du auf so einen Affiliate-Link klickst und über diesen Link einkaufst, bekomme ich von dem betreffenden Online-Shop oder Anbieter eine Provision. Für dich verändert sich der Preis nicht.
          </p>
          <div class="columns pt-5">
            <div class="column">
              <p>LESEZEICHEN</p>
              <p>
                <router-link :to="{name: 'software'}" class="has-spacing-1">Nützliche Programme</router-link>
                <br />
                <router-link :to="{name: 'learning'}" class="has-spacing-1">Elektrotechnik lernen</router-link>
                <br />
                <a class="has-spacing-1" target="_blanks" href="https://besser-lernen.blog">besser-lernen.blog</a>
              </p>
            </div>
            <div class="column">
              <p>TABELLEN</p>
              <p>
                <router-link :to="{name: 'tables-protective-earth'}" class="has-spacing-1">Schutzleiter und Masse (Schaltzeichen)</router-link>
                <br />
                <router-link :to="{name: 'tables-ideal-circuits'}" class="has-spacing-1">Ideale Stromkreise (Schaltzeichen)</router-link>
                <br />
                <router-link :to="{name: 'tables-units-prefixes'}" class="has-spacing-1">Einheitenvorsätze</router-link>
                <br />
                <router-link :to="{name: 'tables-si-units'}" class="has-spacing-1">Internationales Einheitensystem SI</router-link>
                <br />
                <router-link :to="{name: 'tables-constants'}" class="has-spacing-1">Konstanten</router-link>
                <br />
                <router-link :to="{name: 'tables-greek-alphabet'}" class="has-spacing-1">Griechische Buchstaben</router-link>
                <br />
                <router-link :to="{name: 'tables-ascii-characters'}" class="has-spacing-1">ASCII Zeichen</router-link>
                <br />
                <router-link :to="{name: 'tables-electrical-resistivity'}" class="has-spacing-1">Spezifische Widerstände</router-link>
              </p>
            </div>
            <div class="column">
              <p>FORMELSAMMLUNG</p>
              <p>
                <router-link :to="{name: 'formulas-dc'}" class="has-spacing-1">Gleichstrom</router-link>
              </p>
            </div>
            <div class="column is-hidden-mobile">
              <p>RECHTLICHES</p>
              <p>
                <router-link :to="{name: 'data-policy'}" class="has-spacing-1">Datenschutzerklärung</router-link>
                <br />
                <router-link :to="{name: 'impressum'}" class="has-spacing-1">Impressum</router-link>
              </p>
            </div>
            <div class="column">
              <img class="is-play-store-btn is-clickable" src="@/assets/images/google-play-badge.png" @click="openPlayStore()" alt="Jetzt bei Google Play">
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Elektrotechnik App mit Tools, Formeln und Tabellen',
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  methods: {
    openPlayStore () {
      this.$matomo && this.$matomo.trackEvent(this.$router.currentRoute.path, 'click-play-store-btn', '', 0)
      window.open('https://play.google.com/store/apps/details?id=at.elektrotechnik_rechner.twa', '_blank').focus()
    },
    toggleNavDropdown (dropdown) {
      if (dropdown === 'tools') {
        if (this.openToolsDropdown) {
          this.openToolsDropdown = false
        } else {
          this.closeNavDropdowns()
          this.openToolsDropdown = true
        }
      } else if (dropdown === 'knowledge') {
        if (this.openKnowledgeDropdown) {
          this.openKnowledgeDropdown = false
        } else {
          this.closeNavDropdowns()
          this.openKnowledgeDropdown = true
        }
      } else if (dropdown === 'bookmarks') {
        if (this.openBookmarksDropdown) {
          this.openBookmarksDropdown = false
        } else {
          this.closeNavDropdowns()
          this.openBookmarksDropdown = true
        }
      }
    },
    closeNavDropdowns () {
      this.openToolsDropdown = false
      this.openKnowledgeDropdown = false
      this.openBookmarksDropdown = false
    },
    toggleNavbar () {
      const burger = document.querySelector('.navbar-burger')
      const menu = document.getElementById('navMenu')

      burger.classList.toggle('is-active')
      menu.classList.toggle('is-active')

      this.closeNavDropdowns()
    }
  },
  computed: {
    navTitle () {
      return this.$store.state.navTitle
    },
    navTitleRoute () {
      return this.$store.state.navTitleRoute
    },
    isLoading () {
      return this.$store.state.isLoading
    }
  },
  data () {
    return {
      openToolsDropdown: false,
      openKnowledgeDropdown: false,
      openBookmarksDropdown: false
    }
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'start',
    component: () => import(/* webpackChunkName: "about" */ '../views/start.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/legal/impressum.vue')
  },
  {
    path: '/datenschutzerklaerung',
    name: 'data-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/legal/data-policy.vue')
  },
  {
    path: '/rechner',
    name: 'calculators',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/calculators.vue')
  },
  {
    path: '/rechner/akku-ladezeit',
    name: 'calculator-battery-charging-time',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/battery-charging-time.vue')
  },
  {
    path: '/rechner/elektrische-feldstaerke',
    name: 'calculator-electric-field-strength',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/electric-field-strength.vue')
  },
  {
    path: '/rechner/elektrische-ladung',
    name: 'calculator-electric-charge',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/electric-charge.vue')
  },
  {
    path: '/rechner/elektrische-stromdichte',
    name: 'calculator-electric-density',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/electric-density.vue')
  },
  {
    path: '/rechner/gleichstrom-leistung',
    name: 'calculator-dc-power',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/dc-power.vue')
  },
  {
    path: '/rechner/grenzfrequenz-lc-glied',
    name: 'calculator-lc-circuit-cutoff-freq',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/lc-circuit-cutoff-freq.vue')
  },
  {
    path: '/rechner/grenzfrequenz-rc-glied',
    name: 'calculator-rc-circuit-cutoff-freq',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/rc-circuit-cutoff-freq.vue')
  },
  {
    path: '/rechner/zeitkonstante-rc-glied',
    name: 'calculator-rc-circuit-time-constant',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/rc-circuit-time-constant.vue')
  },
  {
    path: '/rechner/induktivitaet-spule',
    name: 'calculator-inductance-coil',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/inductance-coil.vue')
  },
  {
    path: '/rechner/kondensator-kapazitaet',
    name: 'calculator-capacitor-capacitance',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/capacitor-capacitance.vue')
  },
  {
    path: '/rechner/kreisfrequenz',
    name: 'calculator-circular-frequency',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/circular-frequency.vue')
  },
  {
    path: '/rechner/magnetische-durchflutung-spule',
    name: 'calculator-coil-magnetic-flux',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/coil-magnetic-flux.vue')
  },
  {
    path: '/rechner/magnetische-feldstaerke-spule',
    name: 'calculator-coil-magnetic-field-strength',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/coil-magnetic-field-strength.vue')
  },
  {
    path: '/rechner/magnetische-flussdichte',
    name: 'calculator-magnetic-flux-density',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/magnetic-flux-density.vue')
  },
  {
    path: '/rechner/ohmsches-gesetz',
    name: 'calculator-ohms-law',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/ohms-law.vue')
  },
  {
    path: '/rechner/scheinwiderstand-impedanz',
    name: 'calculator-impedance',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/impedance.vue')
  },
  {
    path: '/rechner/transformator-wirkungsgrad',
    name: 'calculator-transformator-efficiency',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/transformator-efficiency.vue')
  },
  {
    path: '/rechner/transistor-gleichstrom',
    name: 'calculator-transistor-dc',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/transistor-dc.vue')
  },
  {
    path: '/rechner/vorwiderstand-led',
    name: 'calculator-led-resistor',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/led-resistor.vue')
  },
  {
    path: '/rechner/wechselstrom-leistung',
    name: 'calculator-ac-power',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/ac-power.vue')
  },
  {
    path: '/rechner/wechselstrom-effektivwerte',
    name: 'calculator-ac-effective-values',
    component: () => import(/* webpackChunkName: "about" */ '../views/calculators/ac-effective-values.vue')
  },
  {
    path: '/konverter',
    name: 'converter',
    component: () => import(/* webpackChunkName: "about" */ '../views/converter/converter.vue')
  },
  {
    path: '/pin-steckerbelegungen',
    name: 'pins',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/pins.vue')
  },
  {
    path: '/pin-steckerbelegungen/raspberry-pi',
    name: 'pins-raspberry-pi',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/raspberry-pi.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-mega',
    name: 'pins-arduino-mega',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-mega.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-micro',
    name: 'pins-arduino-micro',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-micro.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-uno',
    name: 'pins-arduino-uno',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-uno.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-nano',
    name: 'pins-arduino-nano',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-nano.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-due',
    name: 'pins-arduino-due',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-due.vue')
  },
  {
    path: '/pin-steckerbelegungen/arduino-leonardo',
    name: 'pins-arduino-leonardo',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/arduino-leonardo.vue')
  },
  {
    path: '/pin-steckerbelegungen/usb-3-0',
    name: 'pins-usb',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/usb.vue')
  },
  {
    path: '/pin-steckerbelegungen/vga',
    name: 'pins-vga',
    component: () => import(/* webpackChunkName: "about" */ '../views/pins/vga.vue')
  },
  {
    path: '/programme',
    name: 'software',
    component: () => import(/* webpackChunkName: "about" */ '../views/software/software.vue')
  },
  {
    path: '/elektrotechnik-lernen',
    name: 'learning',
    component: () => import(/* webpackChunkName: "about" */ '../views/learning/learning.vue')
  },
  /*
  {
    path: '/lesezeichen',
    name: 'bookmarks',
    component: () => import('../views/bookmarks/bookmarks.vue')
  },
  */
  {
    path: '/tabellen',
    name: 'tables',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/tables.vue')
  },
  {
    path: '/tabellen/einheitenvorsaetze',
    name: 'tables-units-prefixes',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/units-prefixes.vue')
  },
  {
    path: '/tabellen/si-einheiten',
    name: 'tables-si-units',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/si-units.vue')
  },
  {
    path: '/tabellen/konstanten',
    name: 'tables-constants',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/constants.vue')
  },
  {
    path: '/tabellen/griechische-buchstaben',
    name: 'tables-greek-alphabet',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/greek-alphabet.vue')
  },
  {
    path: '/tabellen/ascii-zeichentabelle',
    name: 'tables-ascii-characters',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/ascii-characters.vue')
  },
  {
    path: '/tabellen/spezifische-widerstaende',
    name: 'tables-electrical-resistivity',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/electrical-resistivity.vue')
  },
  {
    path: '/tabellen/schaltzeichen/schutzleiter-masse',
    name: 'tables-protective-earth',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/protective-earth.vue')
  },
  {
    path: '/tabellen/schaltzeichen/ideale-stromkreise',
    name: 'tables-ideal-circuits',
    component: () => import(/* webpackChunkName: "about" */ '../views/tables/ideal-circuits.vue')
  },
  {
    path: '/formelsammlung',
    name: 'formulas',
    component: () => import(/* webpackChunkName: "about" */ '../views/formulas/formulas.vue')
  },
  {
    path: '/formelsammlung/gleichstrom',
    name: 'formulas-dc',
    component: () => import(/* webpackChunkName: "about" */ '../views/formulas/dc.vue')
  },
  {
    path: '/glossar',
    name: 'glossary',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/glossary.vue')
  },
  {
    path: '/glossar/a',
    name: 'glossary-a',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/a.vue')
  },
  {
    path: '/glossar/b',
    name: 'glossary-b',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/b.vue')
  },
  {
    path: '/glossar/c',
    name: 'glossary-c',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/c.vue')
  },
  {
    path: '/glossar/d',
    name: 'glossary-d',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/d.vue')
  },
  {
    path: '/glossar/e',
    name: 'glossary-e',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/e.vue')
  },
  {
    path: '/glossar/f',
    name: 'glossary-f',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/f.vue')
  },
  {
    path: '/glossar/g',
    name: 'glossary-g',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/g.vue')
  },
  {
    path: '/glossar/h',
    name: 'glossary-h',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/h.vue')
  },
  {
    path: '/glossar/i',
    name: 'glossary-i',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/i.vue')
  },
  {
    path: '/glossar/j',
    name: 'glossary-j',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/j.vue')
  },
  {
    path: '/glossar/k',
    name: 'glossary-k',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/k.vue')
  },
  {
    path: '/glossar/l',
    name: 'glossary-l',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/l.vue')
  },
  {
    path: '/glossar/m',
    name: 'glossary-m',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/m.vue')
  },
  {
    path: '/glossar/n',
    name: 'glossary-n',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/n.vue')
  },
  {
    path: '/glossar/o',
    name: 'glossary-o',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/o.vue')
  },
  {
    path: '/glossar/p',
    name: 'glossary-p',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/p.vue')
  },
  {
    path: '/glossar/q',
    name: 'glossary-q',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/q.vue')
  },
  {
    path: '/glossar/r',
    name: 'glossary-r',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/r.vue')
  },
  {
    path: '/glossar/s',
    name: 'glossary-s',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/s.vue')
  },
  {
    path: '/glossar/t',
    name: 'glossary-t',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/t.vue')
  },
  {
    path: '/glossar/u',
    name: 'glossary-u',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/u.vue')
  },
  {
    path: '/glossar/v',
    name: 'glossary-v',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/v.vue')
  },
  {
    path: '/glossar/w',
    name: 'glossary-w',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/w.vue')
  },
  {
    path: '/glossar/x',
    name: 'glossary-x',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/x.vue')
  },
  {
    path: '/glossar/y',
    name: 'glossary-y',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/y.vue')
  },
  {
    path: '/glossar/z',
    name: 'glossary-z',
    component: () => import(/* webpackChunkName: "about" */ '../views/glossary/z.vue')
  },
  {
    path: '/netzwerktechnik',
    name: 'network-engineering',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/network-engineering.vue')
  },
  {
    path: '/wissen/betriebssysteme',
    name: 'theory-operating-systems',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/operating-systems.vue')
  },
  {
    path: '/wissen/netzwerksicherheit',
    name: 'theory-network-security',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/network-security.vue')
  },
  {
    path: '/wissen/datenspeicherung',
    name: 'theory-data-storage',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/data-storage.vue')
  },
  {
    path: '/wissen/netzwerk-fehlersuche',
    name: 'theory-network-debugging',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/network-debugging.vue')
  },
  {
    path: '/wissen/digitale-signatur',
    name: 'theory-digital-signature',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/digital-signature.vue')
  },
  {
    path: '/wissen/asymmetrische-verschluesselung',
    name: 'theory-asymmetric-encryption',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/asymmetric-encryption.vue')
  },
  {
    path: '/wissen/wlan',
    name: 'theory-wlan',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/wlan.vue')
  },
  {
    path: '/wissen/netzwerk-zugriffsverfahren',
    name: 'theory-network-access-methods',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/network-access-methods.vue')
  },
  {
    path: '/wissen/http-protokoll',
    name: 'theory-http-protocol',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/http-protocol.vue')
  },
  {
    path: '/wissen/ftp-protokoll',
    name: 'theory-ftp-protocol',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/ftp-protocol.vue')
  },
  {
    path: '/wissen/osi-modell',
    name: 'theory-osi-model',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/osi-model.vue')
  },
  {
    path: '/wissen/netzwerkmanagement',
    name: 'theory-network-management',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/network-management.vue')
  },
  {
    path: '/wissen/router',
    name: 'theory-router',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/network-engineering/router.vue')
  },
  {
    path: '/elektrische-bauelemente',
    name: 'electrical-elements',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/electrical-elements/electrical-elements.vue')
  },
  {
    path: '/wissen/transformator',
    name: 'theory-transformator',
    component: () => import(/* webpackChunkName: "about" */ '../views/theory/electrical-elements/transformator.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/page-not-found.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('updateLoadingStatus', true)
  window.scrollTo(0, 0)
  next()
})

router.afterEach(() => {
  store.commit('updateLoadingStatus', false)
})

export default router
